import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import BaseComponent from "@/components/base/el/BaseComponent";

export default {
    name: "BaseEmbedComponent",
    mixins: [BaseRenderMixin, BaseComponent],
    props: {
        component_data: Object,
    },
    data() {
        return {
            embed: ""
        };
    },
    created() {
        this.embed = this.component_data.attr['deepsel-embed']
    },
    methods: {
        make_element_tree(createElement, element_data) {
            if (!element_data) return ['']
            let data = {}
            let el, attrs, style, innerHTML

            el = element_data.tag
            attrs = this.get_attrs(element_data)
            style = JSON.parse(JSON.stringify(this.get_style(element_data)))
            innerHTML = element_data.attr['deepsel-embed']

            let transform
            if (this.window_width < 600) transform = this.mobile_transform
            else if (this.window_width < 992) transform = this.tablet_transform
            else transform = this.desktop_transform
            if (transform) style.transform = transform

            if (attrs) data.attrs = attrs
            if (style) data.style = style
            if (innerHTML) data.domProps = {innerHTML: unescape(innerHTML)}

            if (this.$store.state.edit_mode) {
                let class_array = [{'deepsel-focus': this.$store.state.editing === this && !this.$store.state.editing_text}]
                if (this.component_data.attr && this.component_data.attr.class) {
                    let class_value

                    if (Array.isArray(this.component_data.attr.class)) {
                        class_value = this.component_data.attr.class.join(" ")
                    } else class_value = this.component_data.attr.class

                    class_array.push(class_value)
                }
                data = {
                    ...data,
                    class: class_array,
                    on: {
                        mouseenter: this.mouse_enter,
                        mousedown: this.mouse_down,
                    }
                }
            }

            return createElement(
                el,
                data
            )
        },
        save() {
            this.component_data.attr['deepsel-embed'] = this.embed
        },
    },
}